<template>
    <div class="home">
        <loading class="loading" v-show="loading"></loading>
        <div id="cesiumContainer"></div>
        <div>
             <el-row>
                <el-col :span="6">
                <el-statistic title="Daily active users" :value="268500" />
                </el-col>
                <el-col :span="6">
                <el-statistic :value="138">
                    <template #title>
                    <div style="display: inline-flex; align-items: center">
                        Ratio of men to women
                        <el-icon style="margin-left: 4px" :size="12">
                        <Male />
                        </el-icon>
                    </div>
                    </template>
                    <template #suffix>/100</template>
                </el-statistic>
                </el-col>
                <el-col :span="6">
                <el-statistic title="Total Transactions" :value="outputValue" />
                </el-col>
                <el-col :span="6">
                <el-statistic title="Feedback number" :value="562">
                    <template #suffix>
                    <el-icon style="vertical-align: -0.125em">
                        <ChatLineRound />
                    </el-icon>
                    </template>
                </el-statistic>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import loading from '@/components/Loading.vue'
export default {
    components: {
        loading
    },
    data() {
        return {
            like: true,
            value1: 4154.564,
            value2: 1314,
            title: "增长人数",

            loading: true,
            Cesium: window.Cesium,
            viewer: void 0,
            tdt: "b63042738fe261d09c04409bdb327567",
            sdtdt: "9cc3e9deb3cf643b6f133717c333d16d",
            tleList: [
                {
                    "id": '2021-035A',
                    "name": "CSS",
                    "lable": "中国空间站"
                },
                // {
                //     "id": '1998-067A',
                //     "name": "ISS",
                //     "lable": "国际空间站"
                // },
            ],
            beidou: ['BEIDOU 10.json', 'BEIDOU 11.json', 'BEIDOU 12.json', 'BEIDOU 13.json', 'BEIDOU 14.json', 'BEIDOU 15.json', 'BEIDOU 16.json', 'BEIDOU 2 G8.json', 'BEIDOU 3 G2.json', 'BEIDOU 3 G3.json', 'BEIDOU 3 G4.json', 'BEIDOU 3 IGSO-1.json', 'BEIDOU 3 IGSO-2.json', 'BEIDOU 3 IGSO-3.json', 'BEIDOU 3.json', 'BEIDOU 3G1.json', 'BEIDOU 3M1.json', 'BEIDOU 3M10.json', 'BEIDOU 3M11.json', 'BEIDOU 3M12.json', 'BEIDOU 3M13.json', 'BEIDOU 3M14.json', 'BEIDOU 3M15.json', 'BEIDOU 3M16.json', 'BEIDOU 3M17.json', 'BEIDOU 3M18.json', 'BEIDOU 3M19.json', 'BEIDOU 3M2.json', 'BEIDOU 3M20.json', 'BEIDOU 3M21.json', 'BEIDOU 3M22.json', 'BEIDOU 3M23.json', 'BEIDOU 3M24.json', 'BEIDOU 3M3.json', 'BEIDOU 3M4.json', 'BEIDOU 3M5.json', 'BEIDOU 3M6.json', 'BEIDOU 3M7.json', 'BEIDOU 3M8.json', 'BEIDOU 3M9.json', 'BEIDOU 4.json', 'BEIDOU 5.json', 'BEIDOU 6.json', 'BEIDOU 7.json', 'BEIDOU 8.json', 'BEIDOU 9.json', 'BEIDOU IGSO-6.json', 'BEIDOU IGSO-7.json', 'BEIDOU-3 M25.json', 'BEIDOU-3 M26.json']
        
        }
    },

    mounted() {
        // 初始化Cesium
        this.main()
        // this.getProgress()

        // 加载二维地图
        this.addlayer(4)

        // 加载模型
        // this.addModel()

        // 加载卫星轨道
        setTimeout(() => {
            this.addSatellite()
            setTimeout(() => {
                // this.removeSatellite()
            }, 500);
        }, 500);

       
    },

    methods: {
        getProgress() {
            // var helper = new this.Cesium.EventHelper();
            // helper.add(this.viewer.scene.globe.tileLoadProgressEvent, function (e) {
            //     console.log('每次加载地图服务矢量切片都会进入这个回调',e);
            //     if (e== 0) {
            //         console.log("矢量切片加载完成时的回调");
            //         this.loading=false;//关闭加载动画
            //     }
            // });

            // let tilesLoaded = 0;
            // let tilesTotal = 0;

            // this.viewer.scene.globe.tileLoadProgressEvent.addEventListener((queueLength) => {
            //     tilesTotal++;
            //     if (queueLength === 0) {
            //         tilesLoaded++;
            //     }
                
            //     const loadProgress = (tilesLoaded / tilesTotal * 100).toFixed(2);
            //     console.log(`加载进度: ${loadProgress}%`);
                
            //     if (queueLength === 0 && tilesLoaded === tilesTotal) {
            //         console.log("地图加载完成");
            //         this.loading = false
            //     }
            // });
        },

        // 初始化Cesium
        main() {
            this.viewer = new this.Cesium.Viewer('cesiumContainer', {
                shouldAnimate: true,  // 自动执行动画
                geocoder: false, // 隐藏查找位置
                homeButton: false, // 隐藏返回视角到初始位置
                sceneModePicker: false, // 隐藏视角模式的选择
                baseLayerPicker: false, // 隐藏图层选择器
                navigationHelpButton: false, // 隐藏帮助
                timeline: false, // 隐藏时间轴
                fullscreenButton: false, // 隐藏全屏按钮
                animation: false, // 隐藏动画速度控制器
                infoBox: false, // 点击的详情弹窗
            });

            // 开启光照效果
            this.viewer.scene.globe.enableLighting = true

            // 显示帧率
            this.viewer.scene.debugShowFramesPerSecond = true;

            // 初始化位置、高度、角度、
            this.viewer.camera.setView({
                // 经纬度和高程转换为世界坐标
                destination: this.Cesium.Cartesian3.fromDegrees(112.962475, 28.195802, 20000000),
                orientation: {
                    // 方向
                    heading: 6.283185307179581,
                    // 倾角
                    pitch: -1.5688168484696687
                }
            });

            // 设置当前时间为北京时间
            // this.viewer.clock.currentTime = this.Cesium.JulianDate.addHours(this.Cesium.JulianDate.now(new Date()), 8, new this.Cesium.JulianDate());

        },

        // 加载二维地图
        addlayer(type) {
            const Cesium = this.Cesium;
            if (type == 0) {
                //高德矢量图
                let tdtLayer = new Cesium.UrlTemplateImageryProvider({
                    url: "https://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(tdtLayer);
            } else if (type == 1) {
                //高德影像
                let tdtLayer = new Cesium.UrlTemplateImageryProvider({
                    url: "httpss://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(tdtLayer);
            } else if (type == 2) {
                //高德路网中文注记
                let tdtLayer = new Cesium.UrlTemplateImageryProvider({
                    url: "https://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(tdtLayer);
            } else if (type == 3) {
                //矢量地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/vec_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=vec&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 4) {
                //影像地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/img_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=img&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 5) {
                //栅格地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/ter_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=ter&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 6) {
                //标记地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/cia_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=cia&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 7) {
                //Mapbox
                let mapbox = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'basic-v9',
                    // url: 'https://api.mapbox.com/styles/v1',
                    // username: '17660106630',
                    // styleId: 'clysrzhwi001t01pxdzu4agxa',
                    accessToken: 'pk.eyJ1IjoiMTc2NjAxMDY2MzAiLCJhIjoiY2x5c3RmbDl3MG15azJpcWwxMDBpb2Y1NiJ9.lI3wVw-1dz_Y6d85vn0CBA',
                    // scaleFactor:true,
                    // tilesize: 256
                })
                this.viewer.imageryLayers.addImageryProvider(mapbox)
            } else if (type == 8) {
                let clem = new Cesium.WebMapTileServiceImageryProvider({
                    url: "https://trek.nasa.gov/tiles/Moon/EQ/LRO_LOLA_ClrShade_Global_128ppd_v04/1.0.0//{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png",
                    layer: "LRO_LOLA_ClrShade_Global_128ppd_v04",
                    style: "default",
                    format: "image/png",
                    tileMatrixSetID: "default028mm",
                    maximumLevel: 6,
                    tilingScheme: new Cesium.GeographicTilingScheme(),
                    credit: new Cesium.Credit("Clem_UVVIS_FeO_Clr_Global_152ppd"),
                });
                this.viewer.imageryLayers.addImageryProvider(clem)
            }
        },

        // 读取卫星轨道
        satellite(item) {
            var that = this
            // 读取JOSN文件
            const json = require("/public/satelliteData/" + item.name + ".json");

            // 控制速度
            json[0].clock.multiplier = 1;

            // 设置实时时间
            json[0].clock.currentTime = new Date().toISOString();

            // 修改显示文字
            json[1].label.text = item.lable;

            // 修改字体颜色
            // json[1].label.fillColor = { "rgba": [213,255,0,255] };

            // 修改轨道颜色
            // json[1].path.material.solidColor.color = {"rgba": [255,69,0,255]};

            // 隐藏图片
            // json[1].billboard.show = false

            // 加载模型
            // json[1].model = {
            //     "show": true,
            //     "gltf": 'models/kml.glb',
            //     "scale": 100,
            //     "minimumPixelSize": 50,
            // };

            // 加载卫星轨道
            this.viewer.dataSources.add(this.Cesium.CzmlDataSource.load(json)).then(function (dataSource) {
                item.entitiy = dataSource.entities.getById(json[1].id)

                // 获取实时位置
                let cartographic = null
                that.viewer.clock.onTick.addEventListener(function (clock) {
                    var position = item.entitiy.position.getValue(clock.currentTime);
                    cartographic = that.Cesium.Cartographic.fromCartesian(position);
                    var x = that.Cesium.Math.toDegrees(cartographic.longitude);
                    var y = that.Cesium.Math.toDegrees(cartographic.latitude);
                    var h = cartographic.height;
                    // console.log('经度' + x + '纬度' + y + '高度' + h)

                    // that.viewer.scene.camera.setView({
                    //     destination: that.Cesium.Cartesian3.fromDegrees(x, y, h + 100000),
                    //     orientation: {
                    //         heading: 6,
                    //     },
                    // });
                })
            });

        },

        // 加载卫星轨道
        addSatellite() {
            // 添加列表卫星
            for (var i in this.tleList) {
                this.satellite(this.tleList[i])
            }

            // 添加北斗卫星
            // for (var i in this.beidou) {
            //     this.satellite('BEIDOU/' + this.beidou[i].replace(".json",""), this.beidou[i].replace(".json",""))
            // }

        },

        // 加载模型
        addModel() {
            // 加载 GLTF 模型  
            var model = this.viewer.entities.add({
                position: new this.Cesium.Cartesian3.fromDegrees(120.14046454, 30.27415039),
                model: {  
                    uri: "models/kml.glb", // 指向你的 GLTF 文件的路径
                    scale: 100.0,  // 放大比例
                    minimumPixelSize: 50,  //最小尺寸
                }
            });

            // 聚焦模型
            this.viewer.trackedEntity = model;
        },

        removeSatellite() {
            var that = this
            this.viewer.dataSources._dataSources.forEach(function (ds) {
                var id = ds.entities._id
                console.log(id)
                var id1 = that.tleList[1].entitiy.entityCollection._id
                console.log(id1)
                if (id === id1) { // 假设你通过名称来识别数据源  
                    ds.show = false; // 隐藏该数据源  
                }  
            });

            // this.viewer.dataSources.removeAll(true)

        },
  }


}
</script>
<style scoped lang="scss">
    .home {
        width: 100%;
        height: 100%;
        //   transform: rotate(90deg);
    }

    #CesiumContainer {
        width: 100%;
        height: 100%;
    }

    .loading {
        position: absolute;
        top: 100px;
        left: 50px;
    }

    .el-col {
        text-align: center;
    }

</style>

